<template>
  <div class="c-app general-container">
    <TheSidebar />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "./TheSidebar";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";

import langStr from "@/utils/langStr";
import languages from "@/languages/languages";

export default {
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      lang: null,
      languages: languages,
    };
  },
  created() {
    // Saving current Language from user languageId
    const user = this.$store.getters.getCurrentUser;
    this.lang = langStr.get(user.languageId);
    this.$store.dispatch("saveCurrentLang", this.lang);
    this.$store.dispatch("saveLangMessages", this.languages.texts(this.lang));
    if (!this.$store.getters["concessions/concessionList"]) {
      this.$store.dispatch("concessions/concessionList");

    }
  },
};
</script>

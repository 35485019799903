import permissions from "./permissions";
export default [
  {
    _name: "CSidebarNavItem",
    name: "Dashboard",
    to: "/dashboard",
    icon: "cil-gauge",
    items: [],
  },
  {
    _name: "CSidebarNavDropdown",
    name: "Relatórios Básicos",
    route: "/relatorios-basicos",
    icon: "cil-book",
    profiles: permissions.informesBasicos.root,
    items: [
      {
        name: "Valores de referência",
        to: "/relatorios-basicos/valores-de-referencia",
        profiles: permissions.informesBasicos.cifrasDeReferencia,
      },
      {
        name: "Scorecard",
        to: "/relatorios-basicos/scorecard",
        profiles: permissions.informesBasicos.cuadoDeMando,
      },
    ],
  },
  {
    _name: "CSidebarNavDropdown",
    name: "Área comercial",
    route: "/area-comercial",
    icon: "cil-money",
    profiles: permissions.areaComercial.root,
    items: [
      {
        name: "Seguimento de compras & KPI's",
        to: "/area-comercial/seguimento-de-compras-kpis",
        profiles: permissions.areaComercial.seguimientoDeCompras,
      },
      {
        name: "Detalhe dados compras",
        to: "/area-comercial/detalhe-dados-compras",
        profiles: permissions.areaComercial.detalleDatosCompras,
      },
      {
        name: "Previsão de fecho",
        to: "/area-comercial/previsao-de-fecho",
        profiles: permissions.areaComercial.previsionDeCierre,
      },
      {
        name: "Estimativa de incentivos concessão",
        to: "/area-comercial/estimativa-incentivos-concessao",
        profiles: permissions.areaComercial.estimacionIncentivos,
      },
      {
        name: "Estimativa de incentivo de rede",
        to: "/area-comercial/estimativa-incentivos-rede",
        profiles: permissions.areaComercial.estimacionIncentivosRed,
      },
      {
        name: "Bónus Volume",
        to: "/area-comercial/bonus-volume",
        profiles: permissions.areaComercial.bonoVolumen,
      },
    ],
  },
  {
    _name: "CSidebarNavDropdown",
    name: "Área Marketing",
    to: "/art-dmr",
    target: "_blank",
    icon: "cil-volume-high",
    profiles: permissions.areaMarketing.root,
    items: [
      {
        name: "Relatório de Acessórios",
        to: "/area-marketing/relatorio-de-accesorios",
        profiles: permissions.areaMarketing.informeAccesorios,
      },
      {
        name: "Rastreamento DC",
        to: "/area-marketing/rastreamento-dc",
        profiles: permissions.areaMarketing.seguimientoDc,
      },
      {
        name: "Rastreamento EW & SC",
        to: "/area-marketing/rastreamento-ew-sc",
        profiles: permissions.areaMarketing.seguimientoEwSc,
      },
      {
        name: "Informação Incidências NFIT",
        to: "/area-marketing/informacao-incidencias-nfit",
        profiles: permissions.areaMarketing.informeDeAltasenAlias,
      },
    ],
  },
  {
    _name: "CSidebarNavDropdown",
    name: "Área de Garantia",
    route: "/area-de-garantia",
    icon: "cil-shield-alt",
    profiles: permissions.areaGarantias.root,
    items: [
      {
        name: "Recall Campaigns",
        to: "/area-de-garantia/recall-campaigns",
        profiles: permissions.areaGarantias.recallCampaigns,
      },
    ],
  },
  {
    _name: "CSidebarNavDropdown",
    name: "Relatórios Nibsa",
    route: "/relatorios-nibsa",
    icon: "cil-bar-chart",
    profiles: permissions.informesNibsa.root,
    items: [
      {
        name: "Relatório diário",
        to: "/relatorios-nibsa/relatorio-diario",
        profiles: permissions.informesNibsa.parteDiario,
      },
    ],
  },
  {
    _name: "CSidebarNavDropdown",
    name: "Administração",
    to: "/art-dmr",
    target: "_blank",
    icon: "cil-settings",
    profiles: permissions.administracion.root,
    items: [
      {
        name: "Documentação",
        to: "/art-dmr",
        target: "_blank",
        profiles: permissions.administracion.documentacion,
      },
      {
        name: "Gestor de cargas",
        to: "/administracao/gestor-de-cargas",
        profiles: permissions.administracion.gestorDeCargas,
      },
      {
        name: "Gerente de compras ad-hoc",
        to: "/administracao/gestor-compras-ad-hoc",
        profiles: permissions.administracion.gestorComprarAdHoc,
      },
      {
        name: "Gestor de usuários",
        to: "/administracao/gestor-de-usuarios",
        profiles: permissions.administracion.gestorUsuarios,
      },
      {
        name: "Configuração",
        to: "/administracao/configuracao",
        profiles: permissions.administracion.configuracion,
      },
      {
        name: "Estabelecer previsão de fecho",
        to: "/administracao/estabelecer-previsao-de-fecho",
        profiles: permissions.administracion.establecerPrevisionCierre,
      },
      {
        name: "Consulta Invoice",
        to: "/administracao/consulta-invoice",
        profiles: permissions.administracion.consultaInvoice,
      },
    ],
  },
];

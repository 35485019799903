<template>
  <CSidebar
    colorScheme="dark"
    class="side-bar"
    fixed
    size="lg"
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
    dropdownMode="closeInactive"
  >
    <CSidebarBrand class="d-md-down-none flex-column">
      <img src="~@/assets/img/logo-login.png" class="c-sidebar-brand-full" />
      <img
        src="~@/assets/img/art-logo-mini.png"
        class="c-sidebar-brand-minimized"
      />

      <p class="dealer-name">{{ user.name }}</p>
    </CSidebarBrand>

    <!-- <CSidebarNav>
      <CRenderFunction flat :content-to-render="filteredSidebar" />
    </CSidebarNav> -->

    <CRenderFunction flat :content-to-render="filteredSidebar" class="ml-1" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import navES from "./_navES";
import navPT from "./_navPT";
import languages from "@/languages/languages";

export default {
  name: "TheSidebar",
  // nav,
  data() {
    return {
      navES,
      navPT,
      lang: null,
      languages: languages,
      idprofile: "",
      filteredSidebar: [],
      // lang: "pt",
      user: null,
    };
  },
  methods: {
    //Function to filter the sidebar according to the user profile
    //Según idioma de usuario, haga una función u otra para coger un nav u otro
    currentItems() {
      //Refactorizar
      if (this.lang == "es") {
        const currentFilteredItems = navES.map((element) => {
          return {
            ...element,
            items: element.items.filter((subElement) => {
              if (!subElement.profiles) {
                return subElement;
              } else if (subElement.profiles) {
                return subElement.profiles.includes(this.idprofile);
              }
            }),
          };
        });

        const finalItems = currentFilteredItems.filter((item) => {
          return !item.profiles || item.profiles.includes(this.idprofile);
        });

        //Needed to create the SidebarNav component in flat mode:
        return (this.filteredSidebar = [
          {
            _name: "CSidebarNav",
            _children: finalItems,
          },
        ]);
      } else if (this.lang == "pt") {
        const currentFilteredItems = navPT.map((element) => {
          return {
            ...element,
            items: element.items.filter((subElement) => {
              if (!subElement.profiles) {
                return subElement;
              } else if (subElement.profiles) {
                return subElement.profiles.includes(this.idprofile);
              }
            }),
          };
        });

        const finalItems = currentFilteredItems.filter((item) => {
          return !item.profiles || item.profiles.includes(this.idprofile);
        });

        //Needed to create the SidebarNav component in flat mode:
        return (this.filteredSidebar = [
          {
            _name: "CSidebarNav",
            _children: finalItems,
          },
        ]);
      }
    },
    //Fix Minimized Sidebar to have all elements in dropdown visible
    fixMinimizedSidebar() {
      const sidebar = this.$el;
      const menuSections = sidebar.getElementsByClassName(
        "c-sidebar-nav-dropdown"
      );
      Array.from(menuSections).forEach(function (section) {
        section.addEventListener("mouseover", (e) => {
          if (
            e.currentTarget.closest(".side-bar") &&
            e.currentTarget
              .closest(".side-bar")
              .classList.contains("c-sidebar-minimized")
          ) {
            const dropdownMaxHeight =
              window.innerHeight -
              e.currentTarget.getBoundingClientRect().bottom -
              2;
            const dropdown = e.currentTarget.children[1];
            if (dropdown) {
              dropdown.style.maxHeight = `${dropdownMaxHeight}px`;
            }
          }
        });
      });
    },
  },
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
  created() {
    //Cargar el tipo de perfil del usuario. Aunque seguramente que vendrá del store o del dashboard.
    //Ejecutar la función que cargue una sidebar u otra segun el role
    const user = this.$store.getters.getCurrentUser;
    this.user = user;
    this.idprofile = parseInt(user.profileId);
    this.lang = this.$store.getters.getCurrentLang;
    this.currentItems();
  },
  mounted() {
    this.fixMinimizedSidebar();
  },
};
</script>

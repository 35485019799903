<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 188.84 26.84"
    :style="fillStyle"
    :width="width"
    :height="height"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M38.24,0h6.59c0.36,0,0.65,0.29,0.65,0.65v25.32c0,0.36-0.29,0.65-0.65,0.65h-6.59
		c-0.36,0-0.65-0.29-0.65-0.65V0.65C37.59,0.29,37.88,0,38.24,0 M0.65,0.22h9.9c0.36,0,1.8,0.15,2.64,1.45l12.38,19.17V0.87
		c0-0.36,0.29-0.65,0.65-0.65h5.64c0.36,0,0.65,0.29,0.65,0.65v25.32c0,0.36-0.29,0.65-0.65,0.65l-9.64,0.01
		c-0.36,0-2.31-0.01-3.19-1.6l-12-18.67v19.61c0,0.36-0.29,0.65-0.65,0.65H0.65C0.29,26.84,0,26.55,0,26.19V0.87
		C0,0.51,0.29,0.22,0.65,0.22 M156.88,0.22l9.99,0c0.36,0,1.8,0.16,2.64,1.46l12.38,19.17V0.87c0-0.36,0.29-0.65,0.65-0.65h5.64
		c0.36,0,0.65,0.29,0.65,0.65v25.32c0,0.36-0.29,0.65-0.65,0.65l-9.64,0c-0.36,0-2.31,0-3.19-1.58l-12-18.67v19.61
		c0,0.36-0.29,0.65-0.65,0.65h-5.82c-0.36,0-0.65-0.29-0.65-0.65V0.87C156.23,0.51,156.52,0.22,156.88,0.22 M128.98,15.98h10.8
		l-4.41-9.8h-1.99L128.98,15.98z M142.23,21.41h-15.69l-2.13,4.74c-0.16,0.36-0.58,0.65-0.94,0.65h-6.59
		c-0.36,0-0.52-0.29-0.36-0.65L127.9,0.82c0.16-0.36,0.58-0.65,0.95-0.65h11.06c0.36,0,0.78,0.29,0.94,0.65l11.39,25.32
		c0.16,0.36,0,0.65-0.36,0.65h-6.59c-0.36,0-0.78-0.29-0.95-0.65L142.23,21.41z M79.03,6.08H59.48c-0.48,0-0.87,0.39-0.87,0.87v1.5
		c0,0.47,0.38,0.86,0.84,0.87c0,0,16.14,1.52,16.22,1.58c2.86,0.59,5.01,3.29,5.01,6.33v2.9c0,3.66-1.87,6.72-8.21,6.72H51.55
		c-0.31,0-0.55-0.25-0.55-0.55v-4.85c0-0.3,0.25-0.55,0.55-0.55h20.58c0.47-0.01,0.85-0.4,0.85-0.87v-2.04
		c0-0.48-0.39-0.87-0.87-0.87l-14.96-1.28c-0.04,0-0.08-0.01-0.11-0.02C53.68,15.67,51,13.26,51,9.88V6.93
		c0-3.66,2.92-6.63,6.56-6.72h21.47c0.31,0,0.55,0.25,0.55,0.55v4.76C79.58,5.83,79.34,6.08,79.03,6.08 M112.87,6.08H93.32
		c-0.48,0-0.87,0.39-0.87,0.87v1.5c0,0.47,0.38,0.86,0.85,0.87c0,0,16.13,1.52,16.22,1.58c2.86,0.59,5.01,3.29,5.01,6.33v2.9
		c0,3.66-1.87,6.72-8.21,6.72H85.39c-0.3,0-0.55-0.25-0.55-0.55v-4.85c0-0.3,0.25-0.55,0.55-0.55h20.58
		c0.47-0.01,0.84-0.4,0.84-0.87v-2.04c0-0.48-0.39-0.87-0.87-0.87l-14.96-1.28c-0.04,0-0.08-0.01-0.11-0.02
		c-3.35-0.13-6.03-2.54-6.03-5.93V6.93c0-3.66,2.92-6.63,6.56-6.72h21.47c0.3,0,0.55,0.25,0.55,0.55v4.76
		C113.42,5.83,113.18,6.08,112.87,6.08"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "LogoFooter",
  data() {
    return {
      fillStyle: `enable-background: new 0 0 188.84 26.84; fill:${this.fillColor}`,
    };
  },
  props: {
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    fillColor: {
      type: String,
      required: false,
    },
  },
};
</script>

<style>
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
}
</style>

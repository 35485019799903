<template>
  <CHeader fixed with-subheader dark class="header justify-content-between">
    <CToggler
      in-header
      class="ml-2 ml-sm-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />

    <CHeaderNav class="mr-2 mr-md-4">
      <CHeaderNavItem  v-if="invoiceLastUpdateDate" class="header-invoice-data d-none d-sm-flex">
        {{ messages.header.lastInvoiceDate }}: {{ invoiceLastUpdateDate }}
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down mr-1 mx-sm-2 d-flex flex-column align-items-end">
        <small v-if="invoiceLastUpdateDate" class="d-sm-none">{{ messages.header.lastInvoiceDate }}: {{ invoiceLastUpdateDate }}</small>
        <CHeaderNavLink class="align-items-center">
          <CIcon name="cil-account-logout" />
          <CLink class="btn-logout" @click="handleLogout">Log Out</CLink>
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>

    <CSubheader class="px-3 flex-column">
      <span
        v-if="$route.name == 'Escritorio'"
        class="dealer-name border-0 mb-0 pb-0"
        :class="{ welcome: welcome }"
        >{{ messages.header.welcome }} {{ user.name }}
      </span>
      <span
        v-if="$route.name !== 'Escritorio'"
        class="dealer-name border-0 mb-0 pb-0"
        >{{ $route.name.toUpperCase() }}
      </span>
      <CBreadcrumbRouter
        v-if="$route.name !== 'Escritorio'"
        class="border-0 mb-2"
        addClasses="breadcrumbItem"
        addLastItemClasses="lastBreadcrumbItem"
      />
    </CSubheader>
  </CHeader>
</template>

<script>
import Logout from "@/helpers/Logout";
import Api from "@/services/Api";

export default {
  name: "TheHeader",
  data() {
    return {
      welcome: false,
      user: null,
      lang: null,
      messages: null,
      invoiceLastUpdateDate: null,
    };
  },
  methods: {
    handleLogout() {
      Logout();
    },
    checkWelcome() {
      if (this.$route.name == "Escritorio") {
        this.welcome = true;
      }
    },
    getInvoiceLastUpdateDate() {
      const queryUrl = "sys/invoiceLastUpdatedAt";
      Api.sendGet(queryUrl).then((response) => {
        if(response.status === 200) {
          if (response.data.code === 200) {
            this.invoiceLastUpdateDate = response.data.data;
          } else {
            console.error('[API ERROR] invoiceLastUpdatedAt data.code', response.data.code)
          }
        } else {
          console.error('[API ERROR] invoiceLastUpdatedAt', response.status)
        }
      });
    }
  },
  created() {
    this.user = this.$store.getters.getCurrentUser;
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
    this.checkWelcome();
    this.getInvoiceLastUpdateDate();
  },
};
</script>

<template>
  <CFooter :fixed="false" class="footer flex flex--space-between">
    <div>
      <span class="ml-1">&copy; </span>
      <!-- <a href="https://coreui.io" target="_blank">Nissan  </a> -->
      <span class="text">After Sales Reporting Tool </span>
      <span>2021</span>
    </div>
    <LogoFooter
      class="mr-4"
      fill-color="#999c9e"
      width="72px"
      height="10.24px"
    />
  </CFooter>
</template>

<script>
import LogoFooter from "@/components/atoms/LogoFooter";
export default {
  name: "TheFooter",
  components: {
    LogoFooter,
  },
};
</script>

export default {
  informesBasicos: {
    root: [-20, -10, 0, 10, 20, 21],
    cifrasDeReferencia: [-20, -10, 0, 10, 20, 21],
    cuadoDeMando: [-20, -10, 0, 10, 20, 21],
  },
  areaComercial: {
    root: [-20, -10, 0, 10, 20, 21],
    seguimientoDeCompras: [-20, -10, 0, 10, 20, 21],
    detalleDatosCompras: [-20, -10, 0, 10],
    previsionDeCierre: [-20, -10, 0, 10],
    estimacionIncentivos: [-20, -10, 0, 10, 20, 21],
    estimacionIncentivosRed: [-20, -10, 0, 10],
    bonoVolumen: [-20, -10, 0, 10],
  },
  areaMarketing: {
    root: [-20, -10, 0, 10, 20, 21, 30],
    informeAccesorios: [-20, -10, 0, 10],
    seguimientoDc: [-20, -10, 0, 10],
    seguimientoEwSc: [-20, -10, 0, 10],
    informeDeAltasenAlias: [-20, -10, 0, 10, 20, 21, 30],
  },
  areaGarantias: {
    root: [-20, -10, 0, 10, 20, 21],
    recallCampaigns: [-20, -10, 0, 10, 20, 21],
  },
  informesNibsa: {
    root: [-20, -10, 0, 10],
    parteDiario: [-20, -10, 0, 10],
  },
  administracion: {
    root: [-20, -10, 0, 10, 20],
    documentacion: [-20, -10],
    gestorDeCargas: [-20, -10],
    gestorDeReferencias: [-20, -10],
    gestorComprarAdHoc: [-20, -10],
    gestorDescuentosPedidos: [-20, -10],
    gestorDiaFestivos: [-20, -10],
    gestorAltaAlias: [-20, -10],
    gestorUsuarios: [-20, -10, 20],
    configuracion: [-20, -10],
    establecerPrevisionCierre: [-20, -10, 10],
    consultaInvoice: [-20, -10],
    calculoObjetivo: [-20, -10],
  },
};
